<template>
	<div class="container">
		<div class="d-flex justify-content-between align-items-center mb-4">
			<div>
				<h3 class="h3 mb-3">客戶資料明細</h3>
				<nav style="--bs-breadcrumb-divider: '>'">
					<ol class="breadcrumb mb-0">
						<li class="breadcrumb-item">
							<router-link :to="{ name: 'Client' }">客戶管理</router-link>
						</li>
						<li class="breadcrumb-item active">{{ detailData.name }}</li>
					</ol>
				</nav>
			</div>
		</div>

		<table id="data-table" class="table table-borderless">
			<tbody>
				<tr>
					<th>中文姓名</th>
					<td>{{ detailData.name }}</td>
				</tr>
				<tr>
					<th>英文姓名</th>
					<td>{{ detailData.en_name }}</td>
				</tr>
				<tr>
					<th>西元出生年月日</th>
					<td>{{ detailData.birthday }}</td>
				</tr>
				<tr>
					<th>性別</th>
					<td>{{ genderList[detailData.gender] }}</td>
				</tr>
				<tr>
					<th>電話號碼1</th>
					<td>{{ detailData.phone_1 }}</td>
				</tr>
				<tr>
					<th>電話號碼2</th>
					<td>{{ detailData.phone_2 }}</td>
				</tr>
				<tr>
					<th>Email</th>
					<td>{{ detailData.email }}</td>
				</tr>
				<tr>
					<th>客戶ID</th>
					<td>{{ detailData.sid }}</td>
				</tr>
				<tr>
					<th>會員分類</th>
					<td>{{ classificationList[detailData.classification] }}</td>
				</tr>
				<tr>
					<th>評估結果</th>
					<td>{{ detailData.note }}</td>
				</tr>
				<tr>
					<th>引導員</th>
					<td>
						<div v-for="doctor in detailData.doctor" :key="doctor.id">{{ doctor.name }}</div>
					</td>
				</tr>
			</tbody>
		</table>

		<div class="d-flex justify-content-center align-items-center">
			<button class="btn btn-info me-3" @click="edit.showModal()">編輯</button>
			<router-link class="btn btn-primary me-3" :to="{ name: 'ClientLesson', params: { id: detailData.id } }"
				>課程紀錄</router-link
			>
			<router-link class="btn btn-primary" :to="{ name: 'ClientHealthReport', params: { id: detailData.id } }"
				>健康資訊表</router-link
			>
		</div>
	</div>

	<div ref="editModal$" class="modal fade" tabindex="-1" data-bs-backdrop="static">
		<div class="modal-dialog modal-dialog-scrollable">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">編輯客戶</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<Vueform
						ref="editForm$"
						:schema="editFormSchema"
						:display-errors="false"
						validate-on="step"
					></Vueform>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
					<button type="button" class="btn btn-primary" @click="edit.submit()">確定</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { ref, reactive, computed, onMounted } from "vue";
import { Modal } from "bootstrap";
import { useIndexDB } from "@/assets/js/indexDB.js";
import { apiClient } from "@/assets/js/api.js";
export default {
	name: "ClientDetail",
	setup() {
		let clientIDB;
		let doctorIDB;

		const store = useStore();
		const route = useRoute();
		const router = useRouter();

		const detailData = ref({}); // 客戶資料

		const userData = computed(() => store.state.userData); // 登入者資訊

		const genderList = computed(() => store.state.client.genderList); // 性別選單
		const typeList = computed(() => store.state.client.typeList); // 會員身份選單
		const classificationList = computed(() => store.state.client.classificationList); // 會員分類選單
		const doctorList = ref([]);

		const setPageLoading = (isLoading) => store.dispatch("setPageLoading", isLoading);

		//取得 客戶資料
		const getData = async () => {
			setPageLoading(true);

			const data = await clientIDB.get(parseInt(route.params.id));
			setPageLoading(false);

			if (data) {
				data.doctor = data.doctor.map((id) => {
					let name = "";
					let findDoctor = doctorList.value.find((item) => item.id == id);
					if (findDoctor) name = findDoctor.name;

					return {
						id,
						name,
					};
				});

				detailData.value = data;
			} else {
				router.push({ name: "Client" });
			}
		};

		const editModal$ = ref(null);
		const editForm$ = ref(null);
		const editFormSchema = ref({
			name: {
				label: "中文姓名",
				type: "text",
				rules: ["required", "min:2", "max:20"],
				default: "",
			},
			en_name: {
				label: "英文姓名",
				type: "text",
				rules: ["min:2", "max:20"],
				default: "",
			},
			birthday: {
				label: "西元出生年月日",
				type: "date",
				displayFormat: "YYYY-MM-DD",
				loadFormat: "YYYY-MM-DD",
				valueFormat: "YYYY-MM-DD",
				default: "",
			},
			gender: {
				label: "性別",
				type: "select",
				native: false,
				canClear: false,
				canDeselect: false,
				search: true,
				items: genderList.value,
				default: 0,
			},
			phone_1: {
				label: "電話號碼1",
				type: "text",
				inputType: "tel",
				rules: ["required", "min:2", "max:20"],
				default: "",
			},
			phone_2: {
				label: "電話號碼2",
				type: "text",
				inputType: "tel",
				rules: ["min:2", "max:20"],
				default: "",
			},
			email: {
				label: "電子信箱",
				type: "text",
				inputType: "email",
				rules: ["min:3", "max:60"],
				default: "",
			},
			sid: {
				label: "客戶ID",
				type: "text",
				rules: ["min:2", "max:20"],
				default: "",
			},
			type: {
				label: "會員身份",
				type: "select",
				canClear: false,
				canDeselect: false,
				native: false,
				search: true,
				items: typeList.value,
				default: 0,
			},
			classification: {
				label: "會員分類",
				type: "select",
				canClear: false,
				canDeselect: false,
				native: false,
				search: true,
				items: classificationList,
				default: 1,
			},
			note: {
				label: "評估結果",
				type: "textarea",
				rules: ["min:2", "max:60"],
				default: "",
				autogrow: false,
			},
			doctor: {
				label: "引導員",
				type: "tags",
				search: true,
				closeOnSelect: false,
				items: {},
				default: [],
			},
		});

		const edit = reactive({
			modal: {},
			async showModal() {
				editForm$.value.reset();

				let data = await clientIDB.get(detailData.value.id);

				editForm$.value.load(data);

				setTimeout(() => {
					editForm$.value.resetValidators();
					edit.modal.show();
				}, 100);
			},
			submit() {
				const newData = editForm$.value.data;
				editForm$.value.validate().then(() => {
					if (!editForm$.value.invalid) {
						setPageLoading(true);

						newData.doctor = newData.doctor.map((item) => parseInt(item));

						let apiOptions = {
							method: "put",
							id: detailData.value.id,
							data: newData,
						};

						apiClient(apiOptions)
							.then(async (response) => {
								setPageLoading(false);
								if (response.data.status == "0") {
									edit.modal.hide();

									Swal.fire({
										icon: "success",
										text: response.data.message,
									}).then(() => {
										router.push({ name: "Client" });
									});
								} else {
									Swal.fire({
										icon: "warning",
										text: response.data.message,
									});
								}
							})
							.catch(() => {
								setPageLoading(false);
							});
					}
				});
			},
		});

		onMounted(async () => {
			clientIDB = await useIndexDB("clients", "client");
			doctorIDB = await useIndexDB("doctors", "doctor");

			//取得 引導員選單
			doctorList.value = await doctorIDB.getAll();
			editFormSchema.value.doctor.items = doctorList.value.map((item) => {
				return {
					label: item.name,
					value: item.id,
				};
			});

			edit.modal = new Modal(editModal$.value);
			editModal$.value.addEventListener("hidePrevented.bs.modal", (event) => {
				Swal.fire({
					icon: "info",
					text: "是否要關閉表單?",
					showCancelButton: true,
					focusConfirm: false,
					confirmButtonText: "是",
					cancelButtonText: "否",
				}).then((result) => {
					if (result.isConfirmed) {
						edit.modal.hide();
					}
				});
			});

			getData();
		});

		return {
			userData,
			detailData,
			genderList,
			classificationList,
			edit,
			editForm$,
			editFormSchema,
			editModal$,
		};
	},
	beforeRouteEnter(to, from, next) {
		if (!to.params.id) {
			next({ name: "Client" });
		} else {
			next();
		}
	},
};
</script>

<style lang="scss" scoped>
#data-table {
	width: 500px;

	th {
		width: 150px;
	}
}
</style>
